<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
          <div class="tab">
              <el-tabs v-model="activeName" @tab-click="handleClick"  style="margin-left:25px">
                <el-tab-pane label="待审批" name="one"></el-tab-pane>
                <el-tab-pane label="审批记录" name="two"></el-tab-pane>
                </el-tabs>
         
        <div class="SearchBar" v-if="activeName=='two'">
          <!--搜索框-->
          <el-form ref="search" :inline="true" :model="search" size="small" >
            <el-form-item label="" class="lab">
              <el-select v-model="search.timeType"  placeholder="请选择" class="SearchOptions2">
                <el-option
                v-for="item in dateTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item>
               <el-date-picker
                class="SearchDate"
                v-model="searchDatatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
                value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="用户类型" class="lab">
              <el-select v-model="search.userTypeCode" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in useList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="审核结果" class="lab">
              <el-select v-model="search.checkType" clearable placeholder="请选择" class="SearchOptions">
                <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                
                :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="工单号" class="lab">
              <el-input v-model="search.orderCode" clearable></el-input>
            </el-form-item>
           
           <span style="float:right;width:150px">
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
           </span>
          </el-form>
        </div>
        <div class="table">
           <div :class="activeName=='one'?'table-height1':'table-height'">
          <!--数据表格-->
          <el-table :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData1" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='one'"
             
            :stripe="true"
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search1.current)-1)*parseInt(search1.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="orderCode"
              label="工单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="userTypeCodeName"
              label="用户类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="equipmentCodeName"
              label="用气设备"
              align="center"
              show-overflow-tooltip
            >
           
            </el-table-column>
            <el-table-column
              prop="address"
              label="执行位置"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="imageUrlList"
              label="执行简图"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="showImg(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="applyId"
              label="报装人信息"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="desc(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="surveyorName"
              label="测量员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="budgetPrice"
              label="预算价格"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="applyPrice"
              label="申请价格"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="申请原因"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="applyTime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                  <el-button 
                    type="text"
                    size="small"
                    @click="changStatus(row,3)" 
                    >转领导</el-button>
                   <el-button  
                    type="text"
                    size="small"
                    style="color:#008000"
                    @click="changStatus(row,1)" 
                    >通过</el-button>
                      <el-button  
                    type="text"
                    size="small" 
                    style="color:#F56C6C"
                    @click="changStatus(row,2)"
                    >驳回</el-button>
                    
              </template>
            </el-table-column>
          </el-table>
          <el-table :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData2" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='two'"
             
            :stripe="true"
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                :index="(parseInt(search.current)-1)*parseInt(search.size)+1"
                >
                </el-table-column>
            <el-table-column
              prop="orderCode"
              label="工单号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="userTypeCodeName"
              label="用户类型"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="equipmentCodeName"
              label="用气设备"
              align="center"
              show-overflow-tooltip
            >
           
            </el-table-column>
            <el-table-column
              prop="address"
              label="执行位置"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="imageUrlList"
              label="执行简图"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="showImg(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="applyId"
              label="报装人信息"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="desc(row,1)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
              prop="surveyorName"
              label="测量员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="budgetPrice"
              label="预算价格"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="applyPrice"
              label="申请价格"
              align="center"
              show-overflow-tooltip
             
            >
            
            </el-table-column>
            <el-table-column
              prop="remark"
              label="申请原因"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="申请时间"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="checkResult"
              label="审核结果"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.checkStatus==1" type="primary" @click="changStatus(row,1)"  size="small" plain>{{row.checkResult}}</el-tag>
              <el-tag v-if="row.checkStatus==2" type="error" @click="changStatus(row,1)" size="small" plain>{{row.checkResult}}</el-tag>
            </template>
            </el-table-column>
            <el-table-column
              prop="checkUser"
              label="审批人"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
            <el-table-column
              prop="checkTime"
              label="审批时间"
              align="center"
              show-overflow-tooltip
             
            >
            </el-table-column>
          
          </el-table>
          </div>
          <div class="pageBox">
          <el-pagination
            :current-page="activeName=='two'?search.current:search1.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="activeName=='two'?search.size:search1.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
         </div>
      </div>
    </div>
    <el-dialog
     :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
    <el-form ref="addform" :model="form" label-width="120px" label-position="top"  size="mini" >
        <div >
          
          <el-form-item label="审核建议:"  v-if="operatorType==1">
            <el-input type="textarea" v-model="form.remark"  :autosize="{ minRows: 2, maxRows: 4}" maxlength="100" v-if="activeName=='one'" ></el-input>
            <el-input type="textarea" v-model="form.remark"  :autosize="{ minRows: 2, maxRows: 4}" maxlength="100" v-else  disabled ></el-input>
          </el-form-item>
          <el-form-item label="驳回原因:"  v-if="operatorType==2" prop="remark"  :rules="[{message: '请输入驳回原因', trigger: 'blur' ,required: true, }]">
             <el-input type="textarea" v-model="form.remark"   :autosize="{ minRows: 2, maxRows: 4}" maxlength="100"  v-if="activeName=='one'" ></el-input>
             <el-input type="textarea" v-model="form.remark"   :autosize="{ minRows: 2, maxRows: 4}" maxlength="100"  v-else  disabled ></el-input>
          </el-form-item>
          <el-form-item label="审批领导:"  v-if="operatorType==3">
              <el-select v-model="users"  placeholder="请选择" value-key="userId" class="SearchOptions" @change="getName" style="width:250px">
                <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.realName"
                
                :value="item">
                </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="activeName=='one'">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="saveadd">保存</el-button>
     </div>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
    <apply-desc :id=id :type="type" ref="applyDesc"></apply-desc>
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {bzWorkOrderCheck_pagePriceChange,bzWorkOrderCheck_pagePriceChangeRecord,bzWorkOrderCheck_priceChangeCheckPass,bzWorkOrderCheck_priceChangeCheckReject,user_listByPost,bzWorkOrderCheck_transferLeader} from '../../RequestPort/gongfu/gongfu'
import showImg from './showImg.vue'
import applyDesc from '../report/applyDesc.vue'
export default {
  name: 'reportApply',
  components: {showImg,applyDesc},
  mixins: [],
  data() {
    return {
      useList:[],
      statusList:[{ label: '通过', value: '1' },{ label: '驳回', value: '2' }],
      dateTypeList:[{ label: '审核时间', value: '1' },{ label: '申请时间', value: '2' }],
      search1: { current: 1, size: 100}, //搜索
      search: { current: 1, size: 100,timeType:'1'}, //搜索
      searchDatatime:[],
      total: 0,
      tableData1: [], //列表对象
      tableData2: [],
      id:'',
      type:'',
      activeName:'one',
      imgList:[],
      dialogVisible:false,
      operatorType:'',
      form:{},
      title:'转领导审批',
      userList:[],
      users:{}
    }
  },
  mounted() {
      
      this.getList();
  },
  methods: {
      getOption(){
          if(this.activeName=='two'){
            let code = 'YH_TYPE';
            selectDictListByParentCode({parentCode:code}).then(res=>{
            if(res.code==200){
                this.useList = []
                res.data.forEach(el=>{
                  if(el.code!='YH_TYPE5'){
                    this.useList.push(el);
                  }
                })
                
            }
            })
          }
      },
      handleClick(val){
          this.getOption()
          this.getList(1);
      },
      showImg(row){
          if(row.imageUrlList&&row.imageUrlList.length>0){
             this.$refs.imgList.setdialogVisible(true);
             this.imgList = row.imageUrlList;
          }else{
              this.$message.error('暂无执行简图')
          }
          
      },
     
      //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
        timeType:'1'
      }),
       this.searchDatatime=[];
        this.getList(1)
    },
    getList(current) {
      this.search.startTime = ''
        this.search.endTime = ''
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      if(this.activeName=='one'){
          if (current) {
                this.search1.current = 1
            }
          bzWorkOrderCheck_pagePriceChange(this.search1).then(res => {
                this.tableData1 = res.data.records
                this.total = res.data.total
            })
      }
      if(this.activeName=='two'){
        if (current) {
            this.search.current = 1
        }
        bzWorkOrderCheck_pagePriceChangeRecord(this.search).then(res => {
                this.tableData2 = res.data.records
                this.total = res.data.total
            })
      }
        
    },
   

    handleSizeChange(val) {
        if(this.activeName=='one'){
            this.search1.size = val
        }else{
            this.search.size = val
        }
      
      this.getList(1)
    },
    handleCurrentChange(val) {
        if(this.activeName=='one'){
            this.search1.current = val
        }else{
            this.search.current = val
        }
      this.getList()
    },
    changStatus(row,type){
      this.title = ''
      if(this.activeName=='two'){
        this.form.remark = row.checkRemark
      }
        this.form.id = row.id;
        if(type==3){
            this.title = '转领导审批';
            user_listByPost({code:'aj005'}).then(res=>{
            if(res.code==200){
                this.userList = res.data
            }
        })
        }
        this.operatorType = type
        this.dialogVisible = true;
    },
    desc(row,type){
      this.id = row.applyId+"";
      this.type = type+""
      this.$refs.applyDesc.setdialogVisible(true)
    },
    clearnF(){
        this.form={
            id:'',
            remark:'',
            userId:'',
            userName:''
        }
        this.users ={}
        if(this.$refs['addform']){
            this.$refs['addform'].resetFields();
        }
    },
    getName(val){
        this.form.userId = val.userId;
        this.form.userName = val.realName;
    },
    saveadd(){
        if(this.operatorType==1){
            bzWorkOrderCheck_priceChangeCheckPass(this.form).then(res=>{
                this.$message.success('通过成功！')
                this.dialogVisible = false;
                this.getList();
                this.clearnF();
            })
        }
        if(this.operatorType==2){
            this.$refs['addform'].validate(valid => {
                if(valid){
                    bzWorkOrderCheck_priceChangeCheckReject(this.form).then(res=>{
                    this.$message.success('驳回成功！')
                    this.dialogVisible = false;
                    this.getList();
                    this.clearnF();
                    })
                }
                
            
            })
        }
        if(this.operatorType==3){
            this.$confirm('确定转领导进行价格审批吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal:false,
            type: 'warning'
            }).then(() => {
            bzWorkOrderCheck_transferLeader(this.form).then(res=>{
                this.$message.success('转领导成功！')
                this.dialogVisible = false;
                this.getList();
                this.clearnF();
                })
            }).catch(() => {
                  
            });
        }
        
    }
  
  }
}
</script>

<style  lang="less" scoped>
::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.tab{background-color: #fff;border-radius: 6px;margin: 10px;}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin-right: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
   background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;
  height: auto;
}
.table-height {
    height: calc(100vh - 410px);
}
.table-height1 {
    height: calc(100vh - 320px);
    overflow: auto;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
   height: calc(100vh - 90px);
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>
